import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import { callAPI } from '../helpers/api';
import { Error } from './common/Error';
import { CustomSpinner } from './common/CustomSpinner';
import parse from 'html-react-parser';
import { TextType } from '../helpers/enums';

export default function Home({ msalInstance }) {
    let currentUser = useSelector(state => state.application.user); //console.log('currentUser', currentUser);

    const [content, setContent] = useState(null);
    const [loading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const populate = async () => {
        setIsLoading(true);
        setError(null);
        let result = await callAPI('application/' + TextType.MAIN, msalInstance, currentUser.authUser); //console.log('result', result);
        if (result.errorMsg) {
            setError(result.errorMsg);
            setContent(null);
        }
        else {
            setContent(result.json?.text);
        }
        setIsLoading(false);
    }

    useEffect(() => {
        if (content === null && error === null) {
            //console.log("Let's fetch Home page content");
            populate();
        }
    }, [content, error]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <Container fluid={true} className="home" >
            <Row className="justify-content-center">
                <Col xs={12} className="m-4 ">
                    {loading && <CustomSpinner />}
                    {!loading && error && <Error msg={error} />} 
                    {content && parse(content) }
                </Col>
            </Row>
        </Container>
    );
}