import React, { forwardRef } from 'react';
import { Input } from 'reactstrap';
import { objectToString } from '../../helpers/commonHelpers';
//import { CustomSimpleEditor } from './CustomEditor';
import { UserInputType } from '../../helpers/enums';
import TextareaAutosize from 'react-textarea-autosize';

export const CalculatedValueInput = forwardRef(
    (props, ref) => {
        //console.log('props', props);
        const { value, onChange, type, ...properties } = props;
        var inputValue = typeof value === "object" ? objectToString(value) : value.toString();

        //console.log('type', type, typeof value);
        if (type === UserInputType.STRING ||
            type === UserInputType.STRING_ARRAY ||
            type === UserInputType.STRING_DICT ||
            typeof value === "object"
        ) {
            return (<TextareaAutosize {...properties}
                ref={ref}
                value={inputValue}
                maxRows={15}
                minRows={1}
                disabled={true}
                className="form-control"
            />)
        }

        if (typeof value === "object") {
            const propertyCount = Object.keys(value).length;
            const rows = propertyCount > 9 ? 10 : propertyCount + 2;
            return (<Input {...properties}
                type="textarea"
                ref={ref}
                value={inputValue}
                rows={rows}
                disabled={true}
            />);
        }

        return (<Input {...properties}
            type="text"
            ref={ref}
            value={inputValue}
            disabled={true}
        />);
    }
)