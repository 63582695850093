import React, { useState, useEffect } from 'react';
import FormModalBase from '../../common/modals/FormModalBase';
import { getButtonText } from '../../../helpers/commonHelpers';
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { FormGroup, Label, Col, Input } from 'reactstrap';
import { useSelector } from 'react-redux';
import { Action } from '../../../helpers/enums';
import { callAPI } from '../../../helpers/api';

const schema = yup.object().shape({
    name: yup.string().label('Name').required().max(100)
});

export default function QueryModal({
    isOpen,
    toggle,
    status,
    onQueryUpdated,
    msalInstance,
    user
}) {
    let activeQuery = useSelector(state => state.inspector.activeItem);
    const [submitted, setSubmitted] = useState(0);
    const title = status + " query";
    const buttonText = getButtonText(status);

    const { handleSubmit, setError, clearErrors, formState: { errors, isValid }, control, setValue } = useForm({ resolver: yupResolver(schema), mode: 'onChange' });

    const onSubmit = async (data) => {
        let method = 'POST';
        if (data?.id > 0) {
            method = 'PUT';
        }
        let result = await callAPI('query', msalInstance, user, data, method); //console.log('result', result);
        if (result.errorMsg && !result.json) {
            setError("createError", {
                type: "manual",
                message: result.errorMsg
            });
        }
        else {
            if (onQueryUpdated) {
                onQueryUpdated(result.json, status);
            }
            toggle();
        }
        setSubmitted(submitted + 1);
    }

    useEffect(() => {
        clearErrors();
        if (activeQuery && activeQuery.id > 0 && status !== Action.CREATE) {
            setValue('id', activeQuery.id);
            setValue('name', activeQuery.name);
        }
        else {
            setValue('id', 0);
            setValue('name', "");
        }
    }, [activeQuery, status, setValue, clearErrors]);

    return (
        <FormModalBase
            isOpen={isOpen}
            toggle={toggle}
            title={title}
            onSubmit={handleSubmit(data => onSubmit(data))}
            actionButtonText={buttonText}
            formIsValid={isValid}
            setNotLoading={submitted}
        >
            <Controller name="id"
                control={control}
                defaultValue="0"
                render={({ field }) => <Input {...field} type="hidden" />}
            />
            <FormGroup row className="required form-group">
                <Label for="name" sm={3}>Name</Label>
                <Col sm={9}>
                    <Controller
                        name="name"
                        control={control}
                        defaultValue=""
                        render={({ field }) => <Input {...field} type="text" />}
                    />
                    {errors.name && <p className="text-danger">{errors.name.message}</p>}
                </Col>
            </FormGroup>
            {errors.createError && <p className="text-danger">{errors.createError.message}</p>}
        </FormModalBase>
    );
}