import React, { useState } from 'react';
import { ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText } from 'reactstrap';
import { IconType, Role, TextType } from '../../helpers/enums';
import ListHeader from '../common/ListHeader';
import AdminLayout from './AdminLayout';
import ApplicationText from './ApplicationText';

function Application({ msalInstance }) {
    const [activeMenuItem, setActiveMenuItem] = useState(1);
    const title = "Text management";
    const icon = IconType.APPLICATION;

    const setActive = (item) => {
        //console.log('Selected item', item);
        setActiveMenuItem(item);
    }

    const sideMenu = <React.Fragment>
        <ListHeader
            title={title}
            showButtons={false}
            icon={icon}>
        </ListHeader>
        <ListGroup className="m-2 ms-3 me-3 me-lg-0">
            <ListGroupItem
                key="side_menu_item_1"
                action
                tag="button"
                className={activeMenuItem === 1 ? "border-0 active" : "border-0"}
                onClick={() => setActive(1)}
            >
                <ListGroupItemHeading className="mb-0">Intro page</ListGroupItemHeading>
                <ListGroupItemText className="mb-0">Edit home page content</ListGroupItemText>
            </ListGroupItem>
            <ListGroupItem
                key="side_menu_item_2"
                action
                tag="button"
                className={activeMenuItem === 2 ? "border-0 active" : "border-0"}
                onClick={() => setActive(2)}
            >
                <ListGroupItemHeading className="mb-0">Unauthorized page</ListGroupItemHeading>
                <ListGroupItemText className="mb-0">Edit unauthorized page content</ListGroupItemText>
            </ListGroupItem>
        </ListGroup>
    </React.Fragment>

    return (
        <AdminLayout
            title="Manage application texts"
            sideMenu={sideMenu}
            requiredRole={Role.Admin}
        >
            {activeMenuItem === 1 ?
                <ApplicationText
                    msalInstance={msalInstance}
                    textType={TextType.MAIN} />
                :
                <ApplicationText
                    msalInstance={msalInstance}
                    textType={TextType.UNAUTHORIZED} />
            }
        </AdminLayout>
    );
}

export default Application;