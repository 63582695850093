import React, { useState, useRef } from 'react';
import { Container, Row, Col, Button, ButtonToolbar } from 'reactstrap';
import { CustomSpinner } from './CustomSpinner';
import { Error } from './Error';
import CustomTableWithFilters from './CustomTableWithFilters';
import SettingsModal from './modals/SettingsModal';
import { useLocalStorage } from '../../helpers/localStorageHelpers';
import { getDefaultHiddenColumnsArray } from '../../helpers/tableHelpers';

export default function TableFiltersAndButtons({
    title,
    data,
    selectedData,
    setSelectedData,
    columns,
    loading,
    msg = "",
    localStorageKey,
    buttons,
    useRowSelection = true,
    pageSizes = [5, 10, 15],
    tableXl = 10,
    tableClass = "",
    showSettings = false
}) {
    const tableInstance = useRef(null); //console.log('buttons', buttons, msg);
    const defaultHiddenColumns = getDefaultHiddenColumnsArray(columns);//columns && columns.length > 0 ? columns.filter(i => i.default === false) : [];
    //console.log('columns, defaultHiddenColumns', columns, defaultHiddenColumns);
    //console.log('selectedData', selectedData);
    const [filteredRowsCount, setFilteredRowsCount] = useState(0);
    const [clearFilterDisabled, setClearFilterDisabled] = useState(true);
    const [showSettingsModal, setShowSettingsModal] = useState(false);
    const [hiddenColumns, setHiddenColumns] = useLocalStorage(localStorageKey + 'Settings', defaultHiddenColumns);//.length > 0 ? defaultHiddenColumns.map(d => d.accessorKey) : []);

    const getSelectedTableRows = () => {

        return tableInstance?.current?.getSelectedRowModel ? tableInstance.current.getSelectedRowModel().rows : [];
    }

    const getSelectedIds = () => {
        return getSelectedTableRows().map(r => r.id);
    }

    const setSelected = (selected) => {
        //console.log('selected', selected);
        if (setSelectedData) {
            setSelectedData(selected);
        }
    }

    const onSelectedChanged = (selectedIds) => {
        //console.log('selectedIds, data', selectedIds, data);
        if (selectedIds?.length > 0) {
            setSelected(data.filter(d => selectedIds.includes(d.id)));
        }
        else {
            setSelected([]);
        }
    }

    const deselectAll = () => {
        // Empty all selected rows
        if (tableInstance?.current?.toggleAllRowsSelected) {
            tableInstance.current.toggleAllRowsSelected(false);
        }
        setSelected([]);
    }

    const rowCountChanged = (count) => {
        setFilteredRowsCount(count);
        const columnFilters = tableInstance?.current ? tableInstance.current.getState().columnFilters : [];
        //console.log('filters changed', columnsFilters);
        if (!columnFilters || columnFilters.length === 0) {
            setClearFilterDisabled(true);
        }
        else {
            setClearFilterDisabled(false);
        }
    }

    const clearFiltersClicked = () => {
        setClearFilterDisabled(true);
        // Empty all selected rows
        setSelected([]);
        if (tableInstance?.current?.toggleAllRowsSelected) {
            tableInstance.current.toggleAllRowsSelected(false);
        }
        //console.log('tableInstance.current.resetColumnFilters', tableInstance.current.resetColumnFilters);
        if (tableInstance?.current?.resetColumnFilters) {
            tableInstance.current.resetColumnFilters();
        }
    }

    const toggleSettingsModal = () => {
        setShowSettingsModal(!showSettingsModal);
    }

    const onSettingsUpdated = (data) => {
        //console.log('Settings for entities', data);
        setHiddenColumns(data);
    }

    return (
        <React.Fragment>
            <Container fluid={true} className="mb-3">
                <Col lg={12} xl={9}>
                    <h2 className="mt-3">{title}
                        <span className="heading-info ms-2">{filteredRowsCount} / {data.length}</span>
                        {useRowSelection && selectedData && selectedData.length > 0 &&
                            <Button
                                color="link"
                                size="sm"
                                className="pb-1"
                                onClick={() => deselectAll()}>
                                Deselect all ({selectedData.length})
                            </Button>
                        }
                    </h2>
                </Col>
            </Container>
            <Container fluid={true} className="mt-3">
                <Col sm={12}>
                    <Row>
                        <ButtonToolbar>
                            {buttons?.length > 0 && buttons.map((button, index) => 
                                <Button 
                                    key={"button_" + index}
                                    color={button.color} 
                                    className="me-1" 
                                    onClick={() => button.onClick(getSelectedIds())}
                                    disabled={button.disabled}> 
                                    <img className="icon icon-with-text" src={button.icon} alt="" /> 
                                    {button.title} 
                                </Button> 
                            )}
                            <Button
                                color="primary"
                                className="me-1"
                                onClick={() => clearFiltersClicked()}
                                disabled={clearFilterDisabled}>
                                <img className="icon icon-with-text" src="./icons/button_clear_filters.svg" alt="" />
                                Clear filters
                            </Button>
                            {showSettings &&
                                <Button
                                    color="primary"
                                    onClick={() => toggleSettingsModal()}>
                                    <img className="icon" src="./icons/button_settings.svg" alt="" />
                                </Button>
                            }
                        </ButtonToolbar>
                    </Row>
                </Col>
            </Container>
            <Container fluid={true} className="mb-4">
                <Col lg={12} xl={tableXl}>
                    {loading && <CustomSpinner />}
                    {(!loading && msg) && <Error msg={msg} />}
                    {data?.length > 0 &&
                        <CustomTableWithFilters
                        tableColumns={columns}
                        tableData={data}
                        className={tableClass}
                        onSelectedChanged={onSelectedChanged}
                        pageSizes={pageSizes}
                        ref={tableInstance}
                        localStorageKey={localStorageKey}
                        onRowCountChanged={rowCountChanged}
                        useRowSelection={useRowSelection}
                        hiddenColumns={hiddenColumns}
                        />
                    }
                </Col>
            </Container>
            {showSettings && <SettingsModal
                isOpen={showSettingsModal}
                toggle={toggleSettingsModal}
                titlePart="Query output"
                properties={columns}
                onSettingsUpdated={onSettingsUpdated}
                hiddencolumns={hiddenColumns}
            />}
        </React.Fragment>
    );
}