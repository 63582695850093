import React, { useState } from 'react';
import { Container, FormGroup, Label, Input } from 'reactstrap';
import { useForm } from "react-hook-form";
import { Button } from 'reactstrap';
import FormModalBase from './FormModalBase';
import { Action } from '../../../helpers/enums';
import { firstLetterToUpperCase } from '../../../helpers/commonHelpers';
import { getColumnId, getDefaultHiddenColumnsArray } from '../../../helpers/tableHelpers';

const getStates = (properties, notChecked) => {
    if (!properties || properties.length === 0){
        return [];
    } //console.log('properties, notChecked', properties, notChecked);

    return properties.reduce((allStates, prop) => (
        {
            ...allStates,
            [getColumnId(prop)]: notChecked?.includes && notChecked.includes(getColumnId(prop)) ? false : true // If the item can be found from notChecked array, set as false
        }), {}
    );
}

export default function SettingsModal({
    isOpen,
    toggle,
    titlePart,
    properties,
    onSettingsUpdated,
    hiddencolumns
}){
    const { handleSubmit, reset } = useForm(); //console.log('hiddencolumns, properties', hiddencolumns, properties);
    const defaultColumns = properties && properties.length > 0 ? properties.filter(i => i.default === true) : []; //console.log('defaultColumns', defaultColumns);
    const defaultHiddenColumns = getDefaultHiddenColumnsArray(properties);//console.log('defaultHiddenColumns', defaultHiddenColumns);
    const title = firstLetterToUpperCase(titlePart) + " table"

    // Get the checkboxes that are not checked
    let notChecked = hiddencolumns ?? defaultHiddenColumns; //console.log('notChecked', notChecked);
    
    const [checkedState, setCheckedState] = useState(getStates(properties, notChecked));//console.log('checkedState', checkedState);
    const [updated, setUpdated] = useState(0);

    const onSubmit = () => {
        const notChecked = getNotCheckedItems(checkedState);
        onSettingsUpdated(notChecked);
        toggle();
        setUpdated(updated + 1);
    }

    const getNotCheckedItems = (list) =>{
        return Object.keys(list).filter(key => list[key] === false);
    }

    const setActive = (e) => {
        var key = e.target.value;
        //console.log('Selected property', key);
        // console.log('this', e.target)
        // console.log('Is checked', e.target.checked);
        
        let newStates = { ...checkedState }; //console.log('newStates', newStates);
        newStates[key] = e.target.checked;
        setCheckedState(newStates);
    }

    const resetSettings = () => {
        setCheckedState(getStates(properties, defaultHiddenColumns));
    }

    const modalClosed = () => {
        reset(); //console.log('Modal reset');
        setCheckedState(getStates(properties, notChecked));
    }

    return (
        <FormModalBase
            status={Action.SETTINGS}
            isOpen={isOpen}
            toggle={toggle}
            title={title}
            onSubmit={handleSubmit(data => onSubmit(data))}
            additionalButton={<Button color="primary" className="me-1" onClick={() => resetSettings()}>Reset</Button>}
            onModalIsClosing={modalClosed}
            formIsValid={true}
            setNotLoading={updated}
        >
            <Container className="checkboxlist">
                {properties.map((prop) => {
                    const id = getColumnId(prop);
                    return <FormGroup key={id}
                        className={prop.default ?
                            "default form-group form-check" :
                            "form-group form-check"}>
                        <Input
                            type="checkbox"
                            id={"settings_check_" + id}
                            value={id}
                            onChange={setActive}
                            checked={checkedState[id]}
                            disabled={prop.disabled}
                        />
                        <Label check
                            for={"settings_check_" + id}
                            className={prop.disabled ? "ms-2 disabled" : "ms-2"}
                        >
                            {prop.header}
                        </Label>
                    </FormGroup>
                }
                )}
                {defaultColumns.length > 0 &&
                    <p className="mt-3 mb-0 fst-italic">* Default column</p>
                }
            </Container>
        </FormModalBase>
    );
}