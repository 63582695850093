import { DivCell, SimpleDivCell, DateCell } from '../common/Cell';
import { LogStatusText } from '../../helpers/enums';
import { fuzzyMultipleFn } from '../common/ColumnFilters';
import { getValueString, getValueCellDiv } from '../../helpers/commonHelpers';

export const LogColumns = () => {
    return [
        {
            header: 'Batch desc.',
            accessorKey: 'batch',
            filterFn: fuzzyMultipleFn,
            //maxFilterWidth: 60,
            cell: DivCell
        },
        {
            header: 'Type',
            accessorKey: 'type',
            filterFn: fuzzyMultipleFn,
            //maxFilterWidth: 60,
            className: 'w-100px',
            cell: ({ getValue }) => {
                const value = getValue();
                return (<SimpleDivCell value={value}
                    className={value === LogStatusText.ERROR ? "highlightTag neg" : (value === LogStatusText.INFO ? "highlightTag pos" : "highlightTag neutral")}
                />);
            }
        },
        {
            header: 'Message',
            accessorKey: 'message',
            filterFn: fuzzyMultipleFn,
            //maxFilterWidth: 60,
            className: 'text-major',
            cell: DivCell
        }
    ];
}

export const ApiDataColumns = () => {
    return [
        {
            header: 'Batch desc.',
            accessorKey: 'batch',
            filterFn: fuzzyMultipleFn,
            disabled: true,
            default: true,
            //maxFilterWidth: 60,
            cell: DivCell
        },
        {
            header: 'Category',
            accessorKey: 'category',
            filterFn: fuzzyMultipleFn,
            disabled: false,
            default: true,
            //maxFilterWidth: 60,
            cell: DivCell
        },
        {
            header: 'Item code',
            accessorKey: 'item',
            filterFn: fuzzyMultipleFn,
            disabled: true,
            default: true,
            //maxFilterWidth: 60,
            className: 'text-major',
            cell: DivCell
        },
        {
            header: 'Item desc.',
            accessorKey: 'description',
            filterFn: fuzzyMultipleFn,
            disabled: false,
            default: true,
            //maxFilterWidth: 60,
            cell: DivCell
        },
        {
            header: 'Element code',
            accessorKey: 'element',
            filterFn: fuzzyMultipleFn,
            disabled: true,
            default: true,
            //maxFilterWidth: 60,
            className: 'text-major',
            cell: DivCell
        },
        {
            header: 'Type',
            accessorKey: 'type',
            filterFn: fuzzyMultipleFn,
            disabled: false,
            default: false,
            //maxFilterWidth: 60,
            cell: DivCell
        },
        {
            header: 'Value',
            id: 'value',
            // For filtering we need to return string representation of the value (also from arrays and dictionaries)
            accessorFn: row => {
                return getValueString(row.value);
            },
            filterFn: fuzzyMultipleFn,
            disabled: true,
            default: true,
            className: 'text-major',
            cell: ({ row: { original } }) => {
                const val = original.value;
                //console.log('type, val', typeof val, val);
                if (typeof val === 'number' && val === 0) {
                    return val;
                }
                if (!val) {
                    return "";
                }
                if (Array.isArray(val)) {
                    return "[" + val.join(', ') + "]";
                }
                if (typeof val === 'object') {
                    return getValueCellDiv(val, null, 0, false);
                }
                return val;
            }
        },
        {
            header: 'Unit',
            accessorKey: 'unit',
            filterFn: fuzzyMultipleFn,
            disabled: true,
            default: true,
            //maxFilterWidth: 60,
            cell: DivCell
        },
        {
            header: 'Published',
            accessorKey: 'published',
            filterFn: fuzzyMultipleFn,
            disabled: false,
            default: true,
            //maxFilterWidth: 60,
            cell: DateCell
        }
    ];
}